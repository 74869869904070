export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الروسية"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسيت كلمة المرور؟"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل هذه هي المرة الأولى هنا؟"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة كلمة المرور"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاتف أو البريد الإلكتروني:"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعادة كلمة المرور"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللقب:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم:"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الأب:"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الميلاد:"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم العثور على المستخدم"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة من"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة إلى"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرشحات"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة ضبط"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملة"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملة"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملية"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراسل"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمات/التعليق"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على عمليات"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي العمليات"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإيرادات:"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" وحدة بمبلغ"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النفقات:"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنواع العمليات"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفوعات، دفع الخدمات"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويلات الداخلية"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع الفاتورة"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويلات بين الحسابات الخاصة"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء شيكات العملة المشفرة"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنشيط شيكات العملة المشفرة"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أخرى"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إيرادات/نفقات"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإيرادات"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النفقات"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= يساوي"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ لا يساوي"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> أكبر"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ أكبر أو يساوي"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< أقل"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ أقل أو يساوي"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتملت بنجاح"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد المعالجة"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أمس"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسبوع"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ العمليات"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعبئة الحساب"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم اختيار الحساب"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبادل في البورصة"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعبئة العنوان"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويلات داخل المحفظة"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جواز السفر الرقمي"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد من التفاصيل"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف المستخدم:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التسجيل:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر تسجيل دخول:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أصولي"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحسابات"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الكل"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعبئة"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سحب"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد حسابات"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور التأكيدية"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لإكمال العمليات، يجب عليك إنشاء كلمة مرور تأكيدية."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القوالب"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفوعات الشائعة"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأخبار"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إخفاء"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد أصول"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات التسجيل"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التحقق"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عام"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القوالب"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في المجموعة"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعات الدفع"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفوعات"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ الدفع"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب التحويل"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القالب"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القالب:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ القالب"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحويل"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب المراد تعبئته"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طريقة الدفع"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القالب:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ القالب"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القوالب"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعبئة الحساب"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل التحويل"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحقق من التحويل"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن التحويلات"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليمات"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعبئة العنوان"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان المراد تعبئته"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى قائمة المهام"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان التحويل"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة العملية"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ العمليات"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الحسابات"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ غير معروف"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الرموز"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب التحويل:"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حماية الصفقة"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرمز:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توليد"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الحماية:"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أيام"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القالب:"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ كقالب"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحويل"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القوالب"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحويل لمستخدم آخر"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الحماية هو رمز يجب على المستلم إدخاله لإكمال الصفقة. سيرى المستلم التحويل المحمي فورًا في قسم ''تاريخ العمليات''، ولكنه سيتمكن من استخدام الأموال فقط بعد إدخال رمز الحماية في بطاقة المعاملة. إذا انتهت صلاحية الدفع، فسيتم إعادة الأموال إلى المرسل."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان التحويل"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحويل"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويل بين الحسابات الخاصة"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويلات داخل المحفظة"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ ما"])}
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصيد"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات إضافية"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إيداع"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سحب"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء عنوان جديد"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب جديد"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف المحدد"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أصولي"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الحسابات"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الحساب"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حقًا حذف الحسابات؟"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حقًا حذف الحساب؟"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على أصول"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على حسابات"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تسمية العنوان"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حقًا حذف العناوين؟"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حقًا حذف العنوان؟"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع العنوان"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الحساب"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء عنوان"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء عنوان جديد"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب جديد"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأمان"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعداد مكافحة التصيد الاحتيالي هو آلية أمان إضافية عند العمل مع الحساب."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم عرض العبارة الترحيبية التي تحددها في الجزء العلوي من أي صفحة في الحساب."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستظهر العبارة الصحيحة أنك في الموقع الصحيح، وستكون العبارة غير الصحيحة إشارة لعدم إجراء عمليات والاتصال فورًا بقسم الأمان في نظامنا."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمكين مكافحة التصيد الاحتيالي:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عبارة مكافحة التصيد الاحتيالي:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكافحة التصيد الاحتيالي"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ستصبح التغييرات سارية المفعول بعد التسجيل التالي"])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعداد الوصول عبر عنوان IP لن يسمح لك (أو للمخترق) بالعمل مع النظام من عنوان غير مدرج في قائمة العناوين المسموح بها."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييد الوصول إلى الحساب عبر عناوين IP"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة عناوين"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتلة العناوين: "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان IP الخاص بك: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة العناوين"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من العنوان"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى العنوان"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصول"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد عناوين"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حظر"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل عنوان IP"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان IP غير صحيح"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم تحديد أي عنوان IP أو كتلة عناوين."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم السماح بأي عنوان IP للتسجيل. لن تتمكن من الوصول إلى حسابك."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصول عبر IP"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من:"])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلمة السحرية هي إعداد أمان إضافي."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا تم تمكين الكلمة السحرية، فسيطلب منك النظام إدخال بعض الرموز (حسب ترتيبها) من الكلمة السحرية في كل مرة تسجل فيها الدخول."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلمة السحرية:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب الكلمة السحرية:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند الدخول إلى الحساب:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند الدفع عبر التاجر:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند استخدام البطاقة:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلمة السحرية"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تزيد المفاتيح ذات الاستخدام الواحد من أمان النظام بشكل كبير."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عند تسجيل الدخول، سيطلب منك البرنامج إدخال اسم المستخدم وكلمة المرور، وأيضًا المفتاح ذو الاستخدام الواحد. يتم تفعيلها بالتتابع. يبقى المفتاح الأخير ساريًا حتى تقوم بإنشاء سلسلة جديدة من المفاتيح."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدام المفاتيح ذات الاستخدام الواحد"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المفاتيح"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توليد"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفتاح"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفاتيح ذات الاستخدام الواحد"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"كلمة المرور الجديدة\" و \"تأكيد كلمة المرور\" غير متطابقتين."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلسلة من 4 إلى 63 حرفًا. الأحرف المسموح بها هي الأحرف اللاتينية، الأرقام والرموز"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"كلمة المرور الجديدة\" و \"تأكيد كلمة المرور\" غير متطابقتين."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ألا تحتوي كلمة المرور على حروف كيريلية"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء كلمة مرور تأكيد"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة مرور التأكيد"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى الصفحة الرئيسية"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلسلة من 4 إلى 65 حرفًا. الأحرف المسموح بها هي الأحرف اللاتينية، الأرقام والرموز"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في حالة نسيانك كلمة المرور للدخول إلى الحساب، يمكنك استعادتها من خلال الإجابة على السؤال السري."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في هذا القسم يمكنك تغيير السؤال/الإجابة السرية."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال السري"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سؤالك:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجابة:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال السري"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سؤالك الخاص"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصيد"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["زوج التداول:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحسابات:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع التبادل:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التبادل حسب السوق - سيتم استخدام التبادل بأفضل سعر في وقت تنفيذ الطلب. انتباه، يمكن أن يتغير المبلغ المستلم ويختلف عن المبلغ المحسوب حسب الطلبات في وقت التبادل."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التبادل بالسعر الثابت يحدث وفقًا للمبلغ والسعر الذي تحدده. سيتم تقديم الطلب. إذا كان السعر المحدد يختلف بشكل كبير عن سعر السوق، فقد يستغرق إكمال الطلب وقتًا طويلاً."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر (التوقع):"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم تحديد السعر،"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يوصى بتحديد السعر بنفسك"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدام الكل"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمولة:"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمولة:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حسب السوق"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(تبادل سريع بأفضل عرض مضاد)"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر الثابت"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(تقديم طلب بالسعر المحدد)"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد حسابات مناسبة"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التغير خلال 24 ساعة"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقلبات خلال 24 ساعة"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم التداول خلال 24 ساعة"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن أن يكون المبلغ صفرًا!"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبادل"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لرؤية بيانات الملخص، اختر زوج التبادل"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن تبادل الأصول المتشابهة"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيود"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البورصة"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السوق. ملخص"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عروض مشابهة"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عروض مضادة"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعطون"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يستلمون"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتي"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر الصفقات"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ والوقت"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمليات"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمولة"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد عروض"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد صفقات"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد طلبات"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتمل بالكامل"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تبدأ المعالجة"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بدأت المعالجة"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتمل جزئيًا"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إلغاؤه بالكامل"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتمل جزئيًا وتم إلغاؤه"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلم"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الطلبات"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الطلبات"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة ضبط"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرشحات"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمليات"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمولة"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد طلبات"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى للمبلغ الممنوح"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى للمبلغ الممنوح"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى للمبلغ المستلم"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى للمبلغ المستلم"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد محاولات إجراء المعاملة"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل الممنوح"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل المستلم"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الخصم"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الائتمان"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الوكلاء الفرعيين"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم اختيار الحساب"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة من"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة إلى"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أمس"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسبوع"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم اختيار نوع التاريخ"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإنشاء"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانتهاء"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النهاية"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما الجزء الثابت وما الجزء المتغير"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تثبيت المبلغ الممنوح"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تثبيت المبلغ المستلم"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الطلب"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرغب المستخدم في التبادل بسرعة بأفضل العروض المضادة"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التبادل بالسعر المحدد بدقة"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم تبدأ المعالجة"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بدأت المعالجة"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتمل جزئيًا"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتمل بالكامل"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إلغاؤه بالكامل"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتمل جزئيًا وتم إلغاؤه"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل الممنوح"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل المستلم"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع التاريخ"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع التاريخ"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعطاء المبلغ"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلام المبلغ"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرشحات"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفقات"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصفقة"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد عروض"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العروض"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف البائع"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود العرض"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل المشفر"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التحقق من البائع"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للمستخدمين الذين تم التحقق منهم فقط"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكن إجراء صفقة مع نفسك"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الأصل"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعار"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهمة"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الأصول المشفرة"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد أصول مشفرة"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إصدار الرمز"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القبول"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السوق"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدقة"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أولي"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثانوي"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مطابق مع أصل آخر"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم هو الاسم الكامل للأصل المشفر، كما سيراه الجميع: المصدر، المشترون، البائعون، المعالجة وغيرها. السلسلة بطول لا يتجاوز 40 حرفًا"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز هو الاسم القصير للأصل المشفر، وهو الاختصار بطول يتراوح بين 3 إلى 10 أحرف"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الفئة التي تناسب أصلك المشفر"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التنسيقات المدعومة: png، jpg، jpeg. حجم الملف لا يزيد عن 1 ميجابايت، 500*500 بكسل"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشرح لماذا يتم إصدار هذا الأصل المشفر من قبل المصدر، في أي مجال يعمل أو سيعمل، وما هي مهمته"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعار:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهدف / المهمة / الغرض:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدقة:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإصدار:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة الوحدة:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الخانات العشرية للمبلغ. على سبيل المثال، دقة معظم العملات الورقية مثل الدولار الأمريكي أو اليورو تساوي 2."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الوحدات من الأصل المشفر الذي يتم إصداره. سيكون المبلغ المصدر متاحًا على حسابك فور إكمال عملية إنشاء الأصل المشفر."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة المعلنة للوحدة:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضمان:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب:"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشخص الملزم:"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قرار إصدار الأصل المشفر:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير العمليات إلى البلوكشين:"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع البلوكشين:"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمكين العمليات بالأصل المشفر:"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيود/العقوبات:"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد الدول أو المقيمين الضريبيين من القائمة المحظور عليهم استخدام الأصل المشفر في تعاملاتهم:"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيود والشروط الأخرى:"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الاستضافة:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الورقة البيضاء:"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني:"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهات اتصال أخرى:"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابقة"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء أصل مشفر"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم والغرض"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخصائص المالية"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصدر"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصدير إلى البلوكشين"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدود والقيود"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهات الاتصال"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير محدد"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربط ثابت بالعملات الورقية والأصول الأخرى"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المصدر (الشخص الذي يصدر الأصل المشفر)"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المقدم (الشخص الذي يوزع الأصل المشفر)"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرسل"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستلم"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنا (تمكين الرعاية)"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توزيع الأصل المشفر"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل المشفر يعني التمثيل الرقمي للقيمة أو الحق الذي يمكن نقله وتخزينه إلكترونيًا باستخدام تقنية البلوكشين. الأصول المشفرة لا تشمل العملات الورقية، الأوراق المالية والأدوات المالية الأخرى التي تخضع لتنظيم حكومي منفصل."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير أصول التشفير"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سبب رفض القبول"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب مقدم العرض"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب مقدم العرض بالعملة الملزمة"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدم العرض هو ممثل لمصدر الأصل في معاملات الشراء/البيع في السوق الأولية. \nالحساب سلبي. \nيمكن أن يكون حساب إصدار الأصول"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب سلبي، مرتبط بالعملة. \nيجب أن ينتمي إلى نفس المستخدم مثل الحساب السابق"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح للمستخدمين بشراء الأصول من أجهزة الصراف الآلي"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج شراء ما بعد البيع"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتم معاملات السوق الثانوية من خلال البوابات"])},
        "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نموذج مبيعات ما بعد البيع"])},
        "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتم معاملات السوق الثانوية من خلال البوابات"])},
        "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح بالمعاملات مع الأصول في البورصة"])},
        "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح بمعاملات الأصول عبر P2P"])},
        "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت رائد أعمال فرديًا أو ممثلًا لكيان قانوني، فأنت بحاجة إلى تنزيل ملف القرار بشأن إصدار الأصل الرقمي بتنسيق PDF. \nيجب أن يحتوي الحل على جميع الخصائص الرئيسية للأصل الافتراضي وأن يتم توقيعه بتوقيع إلكتروني لرجل الأعمال الفردي أو هذا الكيان القانوني. \nيمكن العثور على مثال لقرار إصدار أصل رقمي على صفحات موقعنا في قسم \\\"المستندات\\\""])},
        "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضع الأصول الافتراضية"])},
        "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الإصدار البديل"])},
        "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر من حسابات المصدر"])}
      },
      "CodeVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصول الافتراضية"])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعداد الإشعارات"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمكين"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإشعار بالعمليات"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسجيل (الدخول إلى الحساب الشخصي، التطبيق المحمول، وغيرها)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإشعار عند إجراء العمليات المالية باستخدام البطاقة"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النفقات (أي مدفوعات، تحويلات، دفع الخدمات، إلخ)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلام الأموال"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلام الرسائل عبر البريد الداخلي"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استلام الفاتورة للدفع"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير إعدادات الأمان"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد العمليات برمز من الرسالة"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد التسجيل برمز من الرسالة"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد عند إجراء العمليات المالية باستخدام البطاقة"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد تغيير إعدادات الأمان برمز من الرسالة"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد النفقات برمز من الرسالة"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم حفظ التغييرات، هل تريد الحفظ؟"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدم الحفظ"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاستلام"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ المعالجة"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد المدفوعات"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منها ناجحة"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منها خاطئة"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفوعات الجماعية"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملة"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتيجة"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مزود الخدمة"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمولة"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة الأولى"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على مدفوعات"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك إجراء عدة مدفوعات في نفس الوقت. للقيام بذلك، تحتاج إلى إنشاء ملف يحتوي على تفاصيل المدفوعات بتنسيق \"XLS\"، \"XLSX\"، \"CSV\" أو \"XML\" مسبقًا."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحليل الملف"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراسل"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الملف"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي المدفوعات: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بإجمالي مبلغ: "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملف المدفوعات"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الخصم"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجراء المدفوعات"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل ملف المدفوعات"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفوعات والتحويلات الجماعية"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتيح خدمة المدفوعات الجماعية تحويل الأموال بسرعة إلى مستخدمين آخرين وإجراء عمليات الدفع لمزودي الخدمة."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في القسم"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل ملف المدفوعات"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تحميل ملف بتنسيق Excel (XLS، XLSX، CSV) أو XML."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة المهام"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتيح لك عرض حالات الطلبات التي تم إنشاؤها سابقًا للمدفوعات الجماعية."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك إنشاء ملف في محرر الجداول Excel."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل نموذج الملف"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات هامة:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنسيق الملف - CSV، XLS أو XLSX."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب أن يكون الملف بدون عناوين."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لتجنب تحويل الأرقام الطويلة إلى تنسيق الأسي (مثل \"5,46546E+15\") وعدم قطع الأصفار الرائدة، يمكن إضافة رمز الاقتباس الفردي (')، أو تعيين التنسيق النصي للخلية قبل إدخال البيانات."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنسيق الأعمدة للمدفوعات إلى مزودي الخدمة"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الأعمدة في الملف:"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود المشغل"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يحدد من قائمة المشغلين المتوفرة"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (يمكن تحديد كود المشغل 7000 لجميع مشغلي شبكات المحمول - في هذه الحالة سيتم تحديد المشغل تلقائيًا حسب رقم الهاتف.)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يحدد المبلغ بعملة الأصل الذي يتم التحويل منه. إذا لم يكن هناك هذا الأصل، فسيتم تطبيق معامل التحويل النظامي تلقائيًا على العملية."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على الدفع"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". ليس من الضروري ملؤها، يمكن تركها فارغة؛ لا يتجاوز طولها 255 حرفًا."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة الأولى للدفع"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يحدد قيمة المعلمة المطلوبة من قبل المشغل، مثل رقم الهاتف. يقتصر العديد من المشغلين على معلمة دفع واحدة، ولكن إذا كان عدد المعلمات أكثر من واحد، فيجب عليك تحديدها جميعًا في الأعمدة المجاورة للعمود الأول."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة الثانية للدفع"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة الثالثة للدفع"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك الاطلاع على معلمات الدفع عند الانتقال إلى دفع أي مشغل:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنسيق الأعمدة للتحويلات الجماعية إلى المستخدمين"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود المشغل"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يحدد 0 أو لا يحدد."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يحدد المبلغ بعملة الحساب الذي يتم التحويل منه. إذا لم يكن هناك هذا الأصل، فسيتم تطبيق معامل التحويل النظامي تلقائيًا على العملية."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على الدفع"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". ليس من الضروري ملؤها، يمكن تركها فارغة؛ لا يتجاوز طولها 255 حرفًا."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب المستلم"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". هنا يحدد رقم حساب المستخدم الذي يجب إجراء التحويل إليه (20 رقمًا)."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنسيق الأعمدة لإصدار القروض الجماعية من قبل وكيل الائتمان"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يشير هذا السطر إلى أن المدفوعات تمثل قروضًا."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يحدد المبلغ بعملة المنتج الائتماني."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على القرض"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". ليس من الضروري ملؤها، يمكن تركها فارغة؛ لا يتجاوز طولها 255 حرفًا."])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف المقترض"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". لاحظ أنه يجب أن يكون لدى المقترض جواز سفر رقمي Mfo-BorrowPerson."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود المنتج الائتماني"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يمكنك الاطلاع عليه في قائمة المنتجات الائتمانية في إصدار القرض اليدوي."])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". فقط عدد الفترات (تحدد المنتجات الائتمانية الفترات المستخدمة، على سبيل المثال، يمكن أن تكون أيام، أسابيع، شهور، إلخ.)"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود المشغل (رقم النموذج)"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". ليس من الضروري ملء هذا والحقول التالية، إذا لم تملأها، فسيتمكن المقترض لاحقًا من سحب القرض بنفسه عبر حسابه الشخصي."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإلغاء في حالة الخطأ"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". إلغاء القرض الممنوح (1) إذا انتهت معاملة السحب بخطأ، أو تركه للسحب الذاتي لاحقًا (0)."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة الأولى للدفع"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". يحدد قيمة المعلمة المطلوبة من قبل المشغل، مثل رقم الهاتف. يقتصر العديد من المشغلين على معلمة دفع واحدة، ولكن إذا كان عدد المعلمات أكثر من واحد، فيجب عليك تحديدها جميعًا في الأعمدة المجاورة للعمود الأول."])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة الثانية للدفع"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمة الثالثة للدفع"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموضوع"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آخر رسالة"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد طلبات"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفئة"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأولوية"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموضوع"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نص الرسالة"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملفات"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تحميل ملف أو عدة ملفات. الحد الأقصى لحجم الملف هو 10 ميجابايت."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منخفض"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عادي"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرتفع"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدعم الفني"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات النشطة"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأرشيف"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب جديد"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتم معالجته من قبل المشغل"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إنشاء الطلب"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم العمل على الطلب، يرجى انتظار رد المشغل"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إغلاق الطلب"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إغلاق الطلب"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد رسائل جديدة"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالة جديدة من المشغل"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد رسائل جديدة"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال إلى الأرشيف"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القوالب"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعبئة"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحويل"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السحب"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القالب"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشغل"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحقول"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد قوالب"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستلم"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حقًا حذف القالب رقم"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تسمية القالب"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تسمية"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدام"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات المراسل"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف / حساب / بريد إلكتروني / هاتف"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف / عنوان العملة المشفرة / بريد إلكتروني / هاتف"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات العامة"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الدفع"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوقيع الرقمي"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاجري"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على متاجر"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء كود الودجت"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء رابط الدفع"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف المتجر"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حقًا حذف المتجر"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل المتجر"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكود"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وضع الدفع"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل المتجر"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن للمتجر الإلكتروني المسجل في النظام استقبال المدفوعات من العملاء عبر بوابة الدفع لدينا."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن تسجيل المتاجر الإلكترونية، مراكز الصرف وأي موارد إنترنت أخرى ترغب في استقبال المدفوعات."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم أو العلامة التجارية"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان URL للمتجر"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف موجز للمتجر"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع الخدمة"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتجر نشط"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفتاح السري."])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السماح بالدفع التلقائي."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال النتائج إلى البرنامج النصي."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان البرنامج النصي"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العودة بعد نجاح الدفع"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد الدفع غير الناجح"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 حرفاً على الأقل. الأحرف المسموح بها: الأحرف اللاتينية والأرقام"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تُستخدم للتحقق من سلامة وصحة البيانات المرسلة من قبل نظام الدفع (عندما يرسل المتجر المستخدم إلى التاجر) ومن قبل المتجر (عند استلام نتائج الدفع)."])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي‘"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع‘"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى قائمة المتاجر"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الفواتير"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على فواتير"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتجر"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل الداخلي"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل الخارجي"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم EN"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرادفات"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلدان"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا تم ملؤه، سيكون الإعلان متاحًا فقط للمستخدمين من البلدان المحددة"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربط بالأصل"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وحدات القياس"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختصار"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدقة"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات إضافية"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتجاه التحويل"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الانتظار (دقائق)"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعلان نشط"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاح فقط بالرابط"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاح فقط للمستخدمين الموثوقين"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للعودة"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشر"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتجاه"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيود"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء إعلان"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أصلك الخارجي"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تربط"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خفض السعر"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد حساب لهذا الأصل"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد السعر"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر صحيح"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خفض السعر"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى أكبر من الحد الأقصى"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أين وكيف تريد استلام التحويل. على سبيل المثال: تحويل في تطبيق البنك على رقم الهاتف +9719990001111 (المستلم ألكسندر ن.)"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصول"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل المطلوبة"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ما هي التفاصيل التي يجب أن يرسلها المستلم لكي تتمكن من إجراء التحويل"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الأصل"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الأصل"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماذا تريد أن تحصل: أموال في الأصل الداخلي، مثل بي تي سي أو في الأصل الخارجي، مثل توكن غولد؟"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب تعليقًا عن ظروف إجراء هذه الصفقة، على سبيل المثال: \\\"يتم نقل الأصل الخارجي فقط عند الاجتماع الشخصي\\\""])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع القيم التي ينتمي إليها الأصل الخارجي، مثل \\\"النقد\\\""])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الأصل لعرضه على المنصة"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكن أيضًا تسمية الأصل بالكلمات من القائمة"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مطابقة مع أصل موجود"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال: لترات، قطع، ساعات، صناديق"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مثال: ل، ق، س، ص"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم عدد الأرقام بعد الفاصلة في قيم الأصل"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد اخترت إنشاء أصل غير موجود بعد، يرجى ملء خصائصه"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت الممنوح للأطراف لتنفيذ الشروط"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الأصل الداخلي هو الأصل الموجود في نظام \\\"نيو ريالتي\\\". الأصل الخارجي هو الأصل غير الموجود في نظام \\\"نيو ريالتي\\"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير مرتبط"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبب"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء الصفقة"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد المشاركة في الصفقة"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض الصفقة"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تمديد وقت الانتظار"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء الصفقة"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة المستخدم في الصفقة غير محددة. حدث خطأ ما"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب التحكيم"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموافقة على التحكيم"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعلام بإتمام التحويل"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد استلام التحويل"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم الصفقة"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال التقييم"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفقة № "])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراسل"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهلة الزمنية"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غدًا"])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفلاتر"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البائع"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعطي"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تستلم"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدود"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفقات"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفقة"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد صفقات"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العروض"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفائدة"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الصفقة"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتجاه"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهلة الزمنية"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد صفقات نشطة"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفقاتي"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل الداخلي"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير نشط"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراسل"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الأصل"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد إعلانات"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعلاناتي"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعلاناتي"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعلان جديد"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير نشط"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكل"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعرف"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتجاه"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل الخارجي"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل الداخلي"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدود"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ النشر"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصيد في الحساب"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب الصفقة"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العرض"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البائع"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهلة الزمنية"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت انتظار الإجراءات من الأطراف"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعطي"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تستلم"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب الصفقة"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حساب"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم اختيار الحساب"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ خارج النطاق: "])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دقيقة"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعطي"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تستلم"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعطي"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["داخلي"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أستلم"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصول الداخلية"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على أصول"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصول الخارجية"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خارجي"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحدد:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الداخلية"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخارجية"])}
      },
      "RequestP2PArbitrage": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب التحكيم"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أمس"])}
      },
      "ExternalCurr": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصول الخارجية"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرتبط بالأصل"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وحدات القياس"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الإنشاء"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاتب"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة من"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة إلى"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفلاتر"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعيين"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد أصول"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اليوم"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أمس"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأسبوع"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهر"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السنة"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرتبط بالأصل"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاتب"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلدان"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير نشط"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعلان"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأدنى"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصى"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حد المبلغ"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الانتظار (دقائق)"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإعلان نشط"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاح فقط بالرابط"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متاح فقط للمستخدمين الموثوقين"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])}
      },
      "ExternalCurrencies": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير الأصل الخارجي"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرادفات"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلدان"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربط بالأصل"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وحدات القياس"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختصار"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدقة"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعيين"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار الكل"])}
    },
    "ATM": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أجهزة الصراف الآلي"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انقر لتوسيع الجدول إلى ملء الشاشة"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعيين"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجار من الباطن"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير نشط"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء جهاز صراف آلي"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير جهاز الصراف الآلي"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات الأساسية"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأساسي"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات العمل"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف مكان التثبيت"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقياسي"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير مقياسي"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات العمل"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاثنين"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الثلاثاء"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأربعاء"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخميس"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجمعة"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبت"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحد"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استراحة الغداء"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكرار كلمة المرور"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدام الحسابات الموجودة"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء حسابات جديدة"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات الأساسية"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ساعات العمل (معلومات مرجعية)"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على آلات التشفير"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الصراف الآلي"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط العرض"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط الطول"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط المساعدة"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الوكيل الفرعي"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحسابات"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يفحص"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملة"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب العبور"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء كريبتومات"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير كريبتومات"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منع"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يسمح"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة جامعي"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة جامع"])},
      "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير قادر على الذهاب إلى أرصدة التحصيل - لا توجد بيانات"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة جامعي"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة جامع"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسموح"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبول"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان"])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير أجهزة الصراف الآلي"])},
      "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض أرصدة التحصيل الحالية"])},
      "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض أرصدة التحصيل المفرغة"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز التعريف الشخصي"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا لم تقم بتحديد وكيل فرعي، فسيتم ربط جهاز الصراف الآلي بك"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خط العرض وخط الطول عبارة عن إحداثيات جغرافية يمكن نسخها، على سبيل المثال، من خرائط جوجل عن طريق إدخال عنوان ماكينة الصراف الآلي"])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع التثبيت"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب تحديد عمود واحد على الأقل لعرض الجدول"])}
    },
    "Echeck": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشيكات الإلكترونية"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء شيك"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النشاط"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الإنشاء"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الخصم"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلسلة"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير نشط"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد شيكات"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنشيط"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغلاق"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء شيك إلكتروني"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الخصم"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة الاسمية لكل شيك"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الشيكات"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عودة"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلسلة:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرمز:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إنشاء الشيكات"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير"])}
      },
      "Activate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنشيط الشيك الإلكتروني"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الائتمان"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلسلة"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز التنشيط"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنشيط"])}
      }
    },
    "Error": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عفوًا، حدث خطأ!"])}
    },
    "ATMIncass": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمع أجهزة الصراف الآلي"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة مجموعة"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المرشحات"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة ضبط"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تعرض المجموعات الأرشيفية"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا تظهر المجموعات الملغاة"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم النقطة"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد مجموعات"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تقديم طلب التحصيل"])}
    },
    "ATMIncassItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة المجموعة"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات الأساسية"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأساسيات"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موزع"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم النقطة"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد البطاقات لكل طلب"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متقبل الفاتورة"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بطاقات"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جامعي النقد"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظة لهواة الجمع"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق على الجمع"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحميل خارج الترتيب"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استبدل كاسيت متقبل الفاتورة"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على الكاسيت"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على البطاقات"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإزالة جميع البطاقات من علبة موزع البطاقات المرفوضة"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بإزالة جميع البطاقات من الأنبوب"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم النقطة هو حقل مطلوب يجب ملؤه"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الكاسيت 1"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الكاسيت 2"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الكاسيت 3"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الكاسيت 4"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف الكاسيت 1"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفواتير في الكاسيت 1"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ في الكاسيت 1"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف الكاسيت 2"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفواتير في الكاسيت 2"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على الشريط 1"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الكاسيت"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف الكاسيت"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفواتير"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على الكاسيت"])}
    },
    "ATMIncassBalance": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الكاسيت"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الكاسيت"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم جمع الكاسيت"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصنيف الكاسيت"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الرفع"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخادم"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أجهزة الصراف الآلي"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في الحقيقة"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كمية"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التعليق على الكاسيت"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد بيانات لعرضها"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمع الجهاز"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صالح"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ج"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بواسطة"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم نقل البقايا بعيدا"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخادم"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفواتير"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أجهزة الصراف الآلي"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفواتير"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موزع"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض/سحب"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متقبل الفاتورة"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفض البطاقات"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بطاقات"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاضِر"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفريغ"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرصدة تحصيل أجهزة الصراف الآلي"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمع الجهاز"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صالح"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كاسيت مثبت في أجهزة الصراف الآلي"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استولى"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم نقل البقايا بعيدا"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم نقل البقايا بعيدا"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كاسيت فارغ:"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصاق:"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات إضافية:"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حتى الآن"])}
    },
    "ATMIncassStatuses": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الحالة"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تقديم طلب التحصيل"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إلغاء طلب التحصيل"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموعة المعتمدة للتنفيذ"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم التحصيل بواسطة أمين الصندوق (يتم تحميل الأموال في أشرطة)"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أشرطة تم تسليمها إلى هواة جمع العملات (المال في الطريق)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحصيل النقدية المثبتة على أجهزة الصراف الآلي"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ونتيجة للفشل، مثقلة مع مجموعة أخرى"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم استبدال التحصيل بتحصيل آخر في ماكينة الصراف الآلي (لا يزال في الطريق)"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم ترحيل أرصدة التحصيل (يتم إرسال التحصيل إلى الأرشيف)"])}
    },
    "CollectorsList": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير المعلومات حول المجمع"])}
    },
    "InfoCollector": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يلغي"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يحفظ"])}
    },
    "ChoiseKeyt": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار حساب مقدم العرض"])}
    },
    "ATMauth": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقول النموذج رقم"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهوية المطلوبة مفقودة"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد قمت بتسجيل الدخول بنجاح، تابع العمليات في ماكينة الصراف الآلي"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة معاملات الصراف الآلي"])}
    },
    "ChoiseColumns": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد الأعمدة لعرضها"])}
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسجيل"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب الشخصي"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الأعمال"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللقب:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الأب: (إذا كان موجودًا)"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحرف اللاتينية، الواصلات والأبوسروفات مسموح بها."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحرف اللاتينية، الواصلات، الفراغات والأبوسروفات مسموح بها."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحرف اللاتينية، الواصلات، الاقتباسات، الفراغات، الأرقام والأبوسروفات مسموح بها."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاتف"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحرف اللاتينية (الحروف الكبيرة والصغيرة)، الأرقام، الرموز _ - . ! $ % ^ * = مسموح بها"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكرار كلمة المرور"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمات المرور غير متطابقة"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عودة"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسجيل"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالي"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيانات المستخدم"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى تقديم عنوان بريد إلكتروني أو رقم هاتف صحيح. يجب إدخال رقم الهاتف بالتنسيق الدولي، على سبيل المثال: +499001234567."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التسجيل بنجاح"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك الآن"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول إلى حسابك الشخصي"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم مراجعة حسابك من قبل موظفينا، وسنبلغك بنتائج التفعيل لاحقًا"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم إرسال رسالة إلى بريدك الإلكتروني تحتوي على رابط لتأكيد التسجيل"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى عنوان بريدك الإلكتروني"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد تسجيل الدخول الأول، سيُطلب منك تأكيد رقم هاتفك"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستلم:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على المستخدم"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهات الاتصال"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التفاصيل"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار المستخدمين"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجاح"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملة"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلمات العامة"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير الأرصدة في الحسابات"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في نهاية العملية:"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في بداية العملية:"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلمات إضافية"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمليات في البلوكشين"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحاولة التالية:"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرد"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حماية الصفقة"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الحماية:"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حتى"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رمز الحماية:"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلمات القالب"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم القالب:"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء العملية"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكرار العملية"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ القالب"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغلاق"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم المعاملة"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملية"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراسل"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الدفع"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمولة"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتيجة"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم استمارة الدفع"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إيداع/سحب"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير أرصدة العناوين"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد الائتمان"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة الأموال إلى المرسل"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملية مشبوهة"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيارات العمل:"])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على المستخدم"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغلاق"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل كلمة مرور التأكيد"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكرار العملية"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ العمليات"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الحسابات"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجاح"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ غير معروف"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القوالب"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أصولي"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الأصل الجديد"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إصدار إضافي"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قائمة الأصول الافتراضية"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإدخال:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر العنوان"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستوطنة"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلد"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنطقة"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المقاطعة"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحي"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنطقة الداخلية للمدينة"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستوطنة"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشارع"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنصر الإضافي للعنوان"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوضيح"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنزل"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرمز البريدي"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب إلى الدعم الفني"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدردشة"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة رسالة"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملفات"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تحميل ملف واحد أو عدة ملفات. الحجم الأقصى للملف هو 10 ميجابايت."])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نص الرسالة"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنشاء ودجت لـ"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحساب"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توليد"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع في"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ الدفع"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات عند النجاح"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإجراءات عند الفشل"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توليد رمز/رابط للدفع"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم عملية المتجر"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلمة إضافية"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط النجاح"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط الفشل"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط الإشعار"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابط الذي سيعود إليه العميل بعد الدفع الناجح"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابط الذي سيعود إليه العميل بعد الفشل"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابط الذي سيتم إرسال الإشعار إليه بنتائج الدفع"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تثبيت المبلغ"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نسخ"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رابط الدفع"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النموذج"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التوثيق"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الحساب في الدقائق، افتراضيًا 60"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدة الرابط"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابط"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النموذج"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الودجت"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير المتجر"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت ترى هذا، لم يتم استلام النموذج (ولكن يجب ألا تراه)"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إصدار إضافي"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأصل المشفر"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب الضمان"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب العمولة"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الإصدار"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للحذف"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمولة"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للايداع"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الملف"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصيغ المسموح بها:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صيغة غير معروفة"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حجم الملف كبير جدًا، الحجم الأقصى: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر أو اسحب الملفات هنا للتحميل"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلغاء"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تحميل الملفات"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملفات"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمسح"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتيجة:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملة:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث الحالة"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روابط مفيدة"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلوكشين:"])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعدادات أمان أخرى"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مطلوب تأكيد"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب تأكيد التفويض بكلمة مرور مؤقتة"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب تأكيد التفويض بالكلمة السحرية"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب تأكيد التسجيل برمز من رسالة SMS"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السلسلة من"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إلى"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رموز"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيمة غير مسموح بها"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف التشفير:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لغة"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صباح الخير"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساء الخير"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساء الخير"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طاب مساؤك"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الخروج"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لكل صفحة"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد الدفع"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب العمولة للاسترداد"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرجاع الدفع"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملة"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجزئة في البلوكشين"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطر AML"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمولة"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للايداع"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت توافق على الشروط المعدلة، أكد الائتمان:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا لم تكن هذه الشروط مناسبة لك، يمكنك إعادة الأموال إلى حساب المرسل مع خصم تكاليف الاسترداد:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعاملة"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بمبلغ"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديها خطر AML"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لذلك تم إعادة حساب العمولة للإيداع"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وبالتالي، المجموع للايداع هو"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على إيرادات جديدة"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الدفع"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استرداد"])}
      }
    }
  },
  "iews": {
    "Echeck": {
      "New": {
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عودة"])}
      }
    }
  }
}