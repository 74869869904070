<template>
    <div>
        <div v-if="this.filesBlock" class="files-uploader-wrapper">
            <FileUpload
                ref="fileUploader"
                :chooseLabel="this.$t('components.service.File.6')"
                :uploadLabel="this.$t('components.service.File.7')"
                :cancelLabel="this.$t('components.service.File.8')"
                :showUploadButton="false"
                name="demo[]" 
                url="/api/upload"
                @select="this.processFiles"
                customUpload 
                :multiple="true" 
                accept="image/*,.pdf,.xlsx,.csv,.xml,.doc,.docx,.docm" 
                :maxFileSize="1000000"
            >
                <template #empty>
                    <p class="m-0">{{ this.$t('components.service.File.5') }}</p>
                </template>
            </FileUpload>
        </div>
    </div>
</template>

<script>
    import FileUpload from 'primevue/fileupload';

    export default {
        components: {
            FileUpload
        },
        props: {
            id: [String, Boolean],
            key: [Number, Boolean],
            arr: {
                type: [Boolean],
                default: false,
            },
            filesBlock: Boolean
        },
        data () {
            return {
                file: '',
                type: false,
                image: '',
                name: '',
                files: [],
            }
        },
        methods: {
            processFiles(event) { 
                this.addIconIfFileNotImage();
                this.getFile(event);
            },
            addIconIfFileNotImage() {
                setTimeout(() => {
                    const filesCollection = document.querySelectorAll('.p-fileupload-file');
                    filesCollection.forEach(fileElement => {
                        const thumbnail = fileElement ? fileElement.querySelector('.p-fileupload-file-thumbnail') : '';
                        const thumbnailSRC = thumbnail ? thumbnail.getAttribute("src") : '';

                        const srcIcon = require(`@/assets/icons/file-text.svg`);

                        !thumbnailSRC ? thumbnail.setAttribute("src", srcIcon) : '';
                        !thumbnailSRC ? thumbnail.classList.add('c50') : '';
                    })

                }, 100);
            },
            getFile(event) {
                this.files = [...event.files];
                if (this.files.length > 0) {
                    this.files.forEach((file, index) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            let res = {};
                            res.name = file.name;
                            res.type = file.type;
                            res.size = file.size;

                            const filesArray = [this.files[index]];
                            const fileItem = new DataTransfer();
                            filesArray.forEach(file => {
                                fileItem.items.add(file);
                            });
                            res.fileObject = fileItem.files;

                            if (this.$lib.inArray(file.type, ['image/jpeg', 'image/jpg', 'image/png'])) {
                                res.image =  reader.result;
                            } else {
                                res.image =  '';
                            }
                            res.body = reader.result.split(',')[1];

                            this.files[index] = res;

                            this.$emit('file-selected', file);
                        }
                        reader.onerror = function (error) {
                            return false;
                        };
                    })
                }

                this.setArrValue({id: this.id, files: this.files});
            },
            setArrValue(arg) {
                this.$emit('updateParentState', {
                    id: arg.id,
                    value: arg.files,
                })
            }
        }
    }
</script>

<style lang="scss">
    .files-uploader-wrapper,
    .p-component {
        font-family: 'HelveticaNeue';
        font-size: 0.875rem;
    }

    .files-uploader-wrapper {
        padding: 1.5rem;
        border-radius: 0 0 12px 12px;
        border-top: 1px solid $color-lightgrey;
        font-family: 'HelveticaNeue';

        div[data-pc-section="buttonbar"] {
            display: flex;
            margin-bottom: 1rem;
            border: none;
            background: transparent;
        }

        .p-button.p-fileupload-choose,
        .p-button[data-pc-name="uploadbutton"],
        .p-button[data-pc-name="cancelbutton"] {
            gap: 0.7rem;
            padding: 0.25rem 0.5rem;
            border-radius: 0.375rem;
            background: transparent;
            color: $color-acent;
            border: 1px solid $color-acent;
            opacity: 1;

            & svg {
                width: 14px;
                height: 14px;
            }
        }

        .p-button:focus,
        .p-button:focus-visible {
            outline: none;
            border: none;
        }

        .p-button.p-disabled {
            filter: saturate(10%);
        }

        .p-button.p-fileupload-choose {
            background: $color-acent;
            color: white;
        }

        div[data-pc-section="content"] {
            border: 2px dashed $color-lightgrey;
            border-radius: 0.375rem;
            background: transparent;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
        }

        .p-progressbar,
        .p-badge-warning.p-fileupload-file-badge {
            display: none;
        }

        .p-fileupload-file {
            width: 100%;
            border: none;
            position: relative;
        }

        .p-fileupload-content {
            position: relative;
            min-height: 38px;
        }

        .p-fileupload-file-actions {
            position: relative;
            position: absolute;
            right: 0;
            top: calc(50% - 20px);
        }

        .p-button.p-fileupload-file-remove:hover {
            background: transparent;
        }
    }
</style>